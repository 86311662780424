import React, { useState } from "react";
import "./styles.css";
import logo from "../logos/logo_mas grueso.png";
import { Link } from "react-router-dom";
import HamburgerMenu from "./HamburgerMenu";
import MenuItem from "./MenuItem";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };
  const handleMenuItemClick = () => {
    if (menuOpen) setMenuOpen(false);
  };
  return (
    <nav className={"navbar" + (menuOpen ? " open" : "")}>
      <Link to="/">
        <img src={logo} className="logo" alt="logo" />
      </Link>
      <HamburgerMenu
        className={menuOpen ? "open" : ""}
        onClick={handleMenuClick}
      />
      <ul className={menuOpen ? "nav-links open" : "nav-links"}>
        <MenuItem onClick={handleMenuItemClick} link="/">
          Inicio
        </MenuItem>
        <MenuItem onClick={handleMenuItemClick} link="/proyectos">
          Proyectos
        </MenuItem>
        <MenuItem onClick={handleMenuItemClick} link="/nosotros">
          Nosotros
        </MenuItem>
        <MenuItem onClick={handleMenuItemClick} link="/contacto">
          Contacto
        </MenuItem>
      </ul>
    </nav>
  );
};

export default Navbar;
