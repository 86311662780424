import "./styles.css";
import { NavLink } from "react-router-dom";

const MenuItem = (props) => {
  return (
    <li>
      <NavLink to={props.link} onClick={props.onClick}>
        {props.children}
      </NavLink>
    </li>
  );
};

export default MenuItem;
