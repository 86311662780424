import React from "react";
import "./styles.css";
import iglogo from "../logos/Instagram_Glyph_Black.png";
import loclogo from "../logos/logo-ubicacion.png";
import maillogo from "../logos/logo-mail.png";
import GoogleMap from "./Map";

const Contact = () => {
  return (
    <div className="contact-container">
      <h2 className="page-title">CONTACTO</h2>
      <div className="contact-info">
        <div>
          <h3>Oficina</h3>
          <div className="logo-text">
            <img src={loclogo} className="loc-logo" alt="loclogo" />
            <p>Manantiales, Maldonado, Uruguay</p>
          </div>
        </div>
        <div>
          <h3>Instagram</h3>
          <a
            href="https://www.instagram.com/interfazuy/"
            target="_blank"
            rel="noreferrer"
            className="logo-text"
          >
            <img src={iglogo} className="ig-logo" alt="iglogo" />
            <p>interfazuy</p>
          </a>
        </div>
        <div>
          <h3>Contacto</h3>
          <div className="logo-text">
            <img src={maillogo} className="mail-logo" alt="maillogo" />
            <p>interfazarqs@gmail.com</p>
          </div>
        </div>
      </div>
      <div className="map">
        <GoogleMap />
      </div>
    </div>
  );
};

export default Contact;
