import bs_01 from "./bs_01.jpeg";
import { default as bs_02 } from "./bs_02.jpeg";
import { default as bs_03 } from "./bs_03.jpeg";
import { default as bs_04 } from "./bs_04.jpeg";
import { default as bs_05 } from "./bs_05.jpeg";
import { default as bs_06 } from "./bs_06.jpeg";
import { default as bs_07 } from "./bs_07.jpeg";
import { default as bs_08 } from "./bs_08.jpeg";
import { default as bs_09 } from "./bs_09.jpeg";

const images = [bs_01, bs_02, bs_03, bs_04, bs_05, bs_06, bs_07, bs_08, bs_09];
export default images;
