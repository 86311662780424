import React from "react";
import "./styles.css";
import BackgroundSlider from "react-background-slider";
import images from "./BackgroudSlider/index";

const Home = () => {
  return (
    <section>
      <BackgroundSlider images={images} duration={3.5} transition={0.5} />
    </section>
  );
};

export default Home;
