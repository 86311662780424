import React, { useEffect, useState } from "react";

const GoogleMap = () => {
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDZHYXwwDpnH4y9UNJ9vtnMWvsA_9zjZV0`;
    script.async = true;
    script.defer = true;
    script.onload = () => setMapLoaded(true); // Trigger map loading
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    // Wait for Google Maps to load
    const interval = setInterval(() => {
      if (window.google && window.google.maps) {
        clearInterval(interval);
        setMapLoaded(true);
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!mapLoaded) return;

    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: -34.905534, lng: -54.826256 },
      zoom: 14,
      styles: [
        { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
        { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#f5f5f5" }] },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#ffffff" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#c9c9c9" }],
        },
      ],
    });

    new window.google.maps.Marker({
      position: { lat: -34.905534, lng: -54.826256 },
      map: map,
      title: "Manantiales, Uruguay",
    });
  }, [mapLoaded]);

  return <div id="map" style={{ width: "100%", height: "400px" }}></div>;
};

export default GoogleMap;
