import React from "react";
import Navbar from "./Navbar/Navbar.js";
import Home from "./Home/Home.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Projects from "./Projects";
// import About from "./About";
import Contact from "./Contact/Contact.js";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/proyectos" element={<Projects />} /> */}
        {/* <Route path="/nosotros" element={<About />} /> */}
        <Route path="/contacto" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
