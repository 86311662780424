import "./styles.css";

const HamburgerMenu = (props) => {
  return (
    <div className={"menu " + props.className} onClick={props.onClick}>
      <span className="vertical"></span>
      <span className="horizontal"></span>
    </div>
  );
};

export default HamburgerMenu;
